import DefaultApiService from '@/services/defaultApi.service'

const ENDPOINT = 'subscriptions'

class SubscriptionService {
  check (data = {}, params = {}) {
    params.type = 'check'
    return DefaultApiService.list(ENDPOINT, params)
  }

  update (data, params = {}) {
    params.type = 'update'
    return DefaultApiService.update(ENDPOINT, data, params)
  }

  reset (data = {}, params = {}) {
    params.type = 'reset'
    return DefaultApiService.update(ENDPOINT, data, params)
  }
}

export default new SubscriptionService()
