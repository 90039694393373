<template>
  <div class="profile" v-show="messagesAvailable">
    <h1 class="sr-only">{{ $t("profile.title") }}</h1>
    <template v-if="currentUser">
      <b-card class="m-3">
        <template #header>
          <b-icon-person-circle/>
          {{ `${user.givenName} ${user.surname} (${user.username})` }}
        </template>

        <b-card-group deck>
          <b-card>
            <template #header>
              <b-icon-person/>
              {{ $t('comp.profile.personalData.header') }}
            </template>
            <b-alert variant="primary" show="">
              <b-icon-info-circle/> {{ $t("comp.profile.dataSourceHint.label") }}
            </b-alert>
            <ul>
              <li><strong>{{ $t("comp.profile.username.label") }}:</strong> {{ user.username }}</li>
              <li><strong>{{ $t("comp.profile.givenName.label") }}:</strong> {{ user.givenName }}</li>
              <li><strong>{{ $t("comp.profile.surname.label") }}:</strong> {{ user.surname }}</li>
              <li><strong>{{ $t("comp.profile.email.label") }}:</strong> {{ user.email }}</li>
            </ul>
          </b-card>
          <b-card>
            <template #header>
              <b-icon-lock/>
              {{ $t('comp.profile.permissions.header') }}
            </template>
            <p>{{ $t("comp.profile.permissions.label") }}</p>
            <ul>
              <li v-for="role in currentUser.roles" :key="role">
                {{ $t(`roles.${role.substring(5).toLowerCase()}.label`) }}
              </li>
            </ul>
          </b-card>
          <b-card v-if="hasSubscriptions"> <!-- Only show if subscriptions available ==> First time selection successfull -->
            <template #header>
              <b-icon-mailbox/>
              {{ $t('comp.profile.subscriptions.header') }}
            </template>
            {{ $t('comp.profile.subscriptions.text') }}
            <b-card class="mt-3">
              <template #header>
                <b-form-checkbox v-model="selectedSubscriptions.nhrsysannounce" :disabled="!hasSubscriptions" name="subscribeToNhrsysannounce" switch>
                  {{ $t('comp.profile.subscriptions.nhrsysannounce.label') }}
                </b-form-checkbox>
              </template>
              {{ $t('comp.profile.subscriptions.nhrsysannounce.text') }}
            </b-card>
            <b-card class="mt-3">
              <template #header>
                <b-form-checkbox v-model="selectedSubscriptions.nhrusers" :disabled="!hasSubscriptions" name="subscribeToNhrusers" switch>
                  {{ $t('comp.profile.subscriptions.nhrusers.label') }}
                </b-form-checkbox>
              </template>
              {{ $t('comp.profile.subscriptions.nhrusers.text') }}
            </b-card>
            <b-card class="mt-3">
              <template #header>
                <b-form-checkbox v-model="selectedSubscriptions.always" :disabled="!hasSubscriptions" name="subscribedAlways" switch>
                  {{ $t('comp.profile.subscriptions.always.label') }}
                </b-form-checkbox>
              </template>
              {{ $t('comp.profile.subscriptions.always.text') }}
            </b-card>
            <template #footer>
              <b-button @click="updateSubscriptions" :disabled="!hasSubscriptions" size="sm" variant="primary">
                <b-icon-clipboard-check/> {{ $t("comp.profile.subscriptions.update.label") }}
              </b-button>
              <b-button @click="resetSubscriptions" :disabled="!hasSubscriptions" size="sm" variant="danger" class="ml-3">
                <b-icon-clipboard-x/> {{ $t("comp.profile.subscriptions.reset.label") }}
              </b-button>
            </template>
          </b-card>
        </b-card-group>
        <b-card-group class="mt-3" deck>
          <b-card>
            <template #header>
              <b-icon-file-text/>
              {{ $t('comp.profile.consent.header') }}
            </template>
            <b-alert variant="success" v-if="user.hasGivenConsent" show>
              <b-icon-check-circle/> {{ $t("comp.profile.hasGivenConsent.label") }}
            </b-alert>
            <template #footer>
              <b-button variant="info" class="mr-3" size="sm" v-b-modal="`consentterms-modal`">
                <b-icon-info/> {{ $t("comp.profile.showConsentTerms.label") }}
              </b-button>
              <b-button @click="withdrawConsent" size="sm" variant="danger">
                <b-icon-x/> {{ $t("comp.profile.withdrawConsent.label") }}
              </b-button>
            </template>
          </b-card>
          <b-card>
            <template #header>
              <b-icon-file-text/>
              {{ $t('comp.profile.touconsent.header') }}
            </template>
            <b-alert variant="success" v-if="user.hasGivenTouconsent" show>
              <b-icon-check-circle/> {{ $t("comp.profile.hasGivenTouconsent.label") }}
            </b-alert>
            <template #footer>
              <b-button variant="info" class="mr-3" size="sm" v-b-modal="`touconsentterms-modal`">
                <b-icon-info/> {{ $t("comp.profile.showTouconsentTerms.label") }}
              </b-button>
              <b-button @click="withdrawTouconsent" size="sm" variant="danger">
                <b-icon-x/> {{ $t("comp.profile.withdrawTouconsent.label") }}
              </b-button>
            </template>
          </b-card>
        </b-card-group>

        <template #footer>
          <b-button @click="logout" variant="primary">
            <b-icon-box-arrow-right/> {{ $t('logout.label') }}
          </b-button>
        </template>
      </b-card>
    </template>
    <b-modal id="consentterms-modal" :title="$t('comp.profile.showConsentTerms.display.label')" size="xl">
      <div class="px-4">
        <Consent/>
      </div>
    </b-modal>
    <b-modal id="touconsentterms-modal" :title="$t('comp.profile.showTouconsentTerms.display.label')" size="xl">
      <div class="px-4">
        <Touconsent/>
      </div>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import { i18nMixin } from '@/mixins/i18n.mixin'
import AuthService from '@/services/auth.service'
import { userServiceForUserView } from '@/services/user.service'
import ConsentService from '@/services/consent.service'
import TouconsentService from '@/services/touconsent.service'
import SubscriptionService from '@/services/subscription.service'
import Consent from '@/components/generic/Consent'
import Touconsent from '@/components/generic/Touconsent'

export default {
  name: 'Profile',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    Consent,
    Touconsent
  },
  data () {
    return {
      user: {},
      hasSubscriptions: null,
      selectedSubscriptions: null,
      defaultSubscriptions: {
        nhrsysannounce: true,
        nhrusers: false,
        always: false
      }
    }
  },
  computed: {
    currentUser () {
      return this.$store.state.auth.user
    },
    pageTitle () {
      if (this.messagesAvailable) {
        return `${this.$i18n.t('profile.title')} | ${this.$i18n.t('app.title')}`
      }
      return null
    }
  },
  watch: {
    currentUser: function (newUser, oldUser) {
      this.getUser()
    }
  },
  created () {
    this.getUser()
  },
  methods: {
    getUser () {
      if (this.currentUser) {
        userServiceForUserView.get(this.currentUser.username).then((user) => {
          this.user = user
          this.hasSubscriptions = this.checkSubscriptions(user)
        }).catch(error => {
          console.log(error)
        })
      }
    },
    withdrawConsent () {
      ConsentService.withdraw(this.user.username).then(() => {
        this.$router.push({ path: '/' })
        this.$store.dispatch('auth/ping')
      })
    },
    withdrawTouconsent () {
      TouconsentService.withdraw(this.user.username).then(() => {
        this.$router.push({ path: '/' })
        this.$store.dispatch('auth/ping')
      })
    },
    checkSubscriptions (user) {
      if (_.isEmpty(user.subscriptions)) {
        this.selectedSubscriptions = this.defaultSubscriptions
        return false
      } else {
        this.selectedSubscriptions = user.subscriptions
        return true
      }
    },
    updateSubscriptions () {
      SubscriptionService.update(this.selectedSubscriptions).then(() => {
        this.$store.dispatch('auth/ping')
        this.makeToast(
          this.$i18n.t('subscription.update.text'),
          this.$i18n.t('subscription.update.title'),
          'success'
        )
      })
    },
    resetSubscriptions () {
      SubscriptionService.reset().then(() => {
        this.$store.dispatch('auth/ping')
        this.makeToast(
          this.$i18n.t('subscription.reset.text'),
          this.$i18n.t('subscription.reset.title'),
          'success'
        )
      })
    },
    makeToast (message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant
      })
    },
    logout () {
      AuthService.logout()
    }
  }
}
</script>
